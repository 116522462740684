<template>
  <div class="containerBox">
    <div>
      <h3>Pagamento</h3>
    </div>
    <div class="boxValues">
      <div
        class="d-flex justify-content-between align-items-center containerInputs"
      >
        <div class="d-flex">
          <div
            v-for="item in dataFields"
            :key="item.value"
            :class="
              boxCard === item.value || boxCard === 'allCard'
                ? 'box check mr-2'
                : 'box mr-2'
            "
          >
            <label class="containerCheck"
              >{{ item.label }}
              <input
                type="radio"
                @click="checkeds(item)"
                :checked="boxCard === item.value"
                name="radios"
                class="input"
              />
              <span class="checkmark"></span>
            </label>
            <div class="d-flex align-items-center justify-content-center">
              <money
                v-if="item.change"
                key="money"
                v-model="item.valueMoney"
                v-bind="money"
                @blur.native="returnValues(item.valueMoney)"
                class="input_default inputValue"
                :disabled="item.disabled"
              ></money>
            </div>
          </div>
        </div>
        <!-- {{ 312.2 | currency }} -->
        <p class="values">{{ addTotalAmount | currency }}</p>
        <div class="flex">
          <div class="box boxThing">
            <label class="containerCheck">
              Troco
            </label>
            <div class="d-flex align-items-center justify-content-center">
              <money
                key="money"
                v-model="amountValue"
                class="input_default inputValue"
                disabled
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  components: {
    Money
  },
  data() {
    return {
      valorCliente: "",
      boxCard: "money",
      dataFields: [
        {
          label: "Dinheiro",
          value: "money",
          valueMoney: "",
          disabled: false,
          change: true
        },
        {
          label: "Cartão",
          value: "card",
          valueMoney: "",
          disabled: true,
          change: true
        },
        {
          label: "Pix/Transf",
          value: "pix",
          valueMoney: "",
          disabled: true,
          change: true
        },
        {
          label: "Mais de um",
          value: "allCard",
          valueMoney: "",
          disabled: true,
          change: false
        }
      ],
      amountValue: "0",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      valueMoney: 0,
      valueMoneyCard: 0,
      valueMoneyPix: 0,
      checkValue: false
    };
  },
  computed: {
    ...mapGetters(["getStateWatch"]),
    addTotalAmount() {
      //soma de todos os valores
      const values = [];
      this.dataFields.forEach(item => {
        values.push(item.valueMoney);
      });

      const valuesAmount = values.reduce((value, currentValue) => {
        return value + currentValue;
      });
      // this.$store.commit("payValue", valuesAmount);
      return valuesAmount;
    }
  },

  methods: {
    ...mapMutations(["setStateWatch", "payValue"]),
    checkeds({ value }) {
      this.boxCard = value;
      this.dataFields.forEach(item => {
        if (value === "allCard" && item.change) {
          item.disabled = false;
        }

        if (value !== "allCard") {
          item.disabled = true;
        }

        if (value === "money" && item.value === "money") {
          item.disabled = false;
        }

        // if(value === 'money' && this.dataFields[0].valueMoney === 0) {
        //   return
        // }

        if (value == item.value && value !== "money" && item.change) {
          item.valueMoney = this.data.total;
          this.returnValues(item.valueMoney);
          return;
        }

        item.valueMoney = 0;
      });
    },
    valuePay() {
      this.setStateWatch(false);
      this.payValue(false);

      const { total } = this.data;
      const { dataFields } = this;

      this.valuesPays = [];
      let allFields = [];

      dataFields.forEach(item => {
        allFields.push(item);
      });

      this.dataFields.forEach(item => {
        if (
          item.label === "Dinheiro" &&
          item.valueMoney === 0 &&
          this.boxCard === "money"
        ) {
          this.checkValue = false;
          return;
        }
        if (
          item.label === "Dinheiro" &&
          item.valueMoney >= total &&
          this.boxCard === "money"
        ) {
          this.checkValue = true;
        }
      });

      if (this.valuesPays.length < 3 && this.checkValue) {
        for (let i = 0; i <= 2; i++) {
          let value = {
            typePayment: i,
            valuePayment: Number(allFields[i].valueMoney)
            // name: allFields[i].label
          };
          this.valuesPays.push(value);
        }

        // console.log(this.valuesPays)
        this.payValue(this.valuesPays);
      }
    },
    returnValues(value) {
      const { boxCard } = this;
      const { total } = this.data;
      this.checkValue = true;
      if (boxCard === "allCard") {
        // selecionar todos
        this.amountValue = 0;

        if (this.addTotalAmount > total) {
          this.toast(
            "Valor informado é maior que o do pedido. Para pagar com mais de um meio, não deve haver troco.",
            this.$toast.error
          );
          this.checkValue = false;
          return;
        }

        if (this.addTotalAmount < total) {
          this.toast(
            "Valor informado é menor que o do pedido.",
            this.$toast.error
          );
          this.checkValue = false;
          return;
        }

        this.amountValue = this.addTotalAmount - total;
      }

      if (boxCard === "card" || boxCard === "pix") {
        if (value === total) {
          // sempre vai ser o mesmo valor
          this.amountValue = 0;
          this.checkValue = true;
          return;
        }
        this.toast(
          "Para Cartão ou Pix o valor deve ser o mesmo do pedido!",
          this.$toast.error
        );
      }

      if (boxCard === "money") {
        // se for dinheiro

        if (value < total) {
          this.toast(
            "Valor informado é menor que o valor do pedido!",
            this.$toast.error
          );
          this.checkValue = false;
          return;
        }

        this.amountValue = value - total;
      }
    }
  },
  watch: {
    getStateWatch(newValue) {
      // if(newValue) { // se for verdade }
      if (newValue) {
        this.valuePay();
      }
    }
  }
};
</script>

<style scoped>
.containerBox h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.boxValues {
  background: #ffffff;
  border-radius: 8px;
  height: 100px;
}

.check {
  background: #f38235 !important;
}

.check .containerCheck {
  color: #fff !important;
}

.inputValue {
  border: none;
  border-radius: 6px;
  width: 90%;
  height: 38px;
  outline: #f38235;
  padding: 0 0 0 5px;
  color: #f38235;
}

.containerCheck {
  display: block;
  position: relative;
  padding: 0 0 0 21px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #797984;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 10px;
}

.containerCheck .input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f38235;
}

.containerCheck:hover .input ~ .checkmark {
  background-color: #fff;
}

.containerCheck .input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheck .input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f38235;
}

.values {
  color: #f38235;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

label {
  margin: 0;
}

.boxThing {
  height: 100%;
}

.boxThing label {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #797984;
  margin-bottom: 0;
  padding: 0;
}

.box {
  background: #ffffff;
  border: 1px solid #e9e9eb;
  border-radius: 6px;
  width: 140px;
}

.containerInputs {
  height: 100%;
  padding: 10px 16px;
}

.containerInputs > div {
  height: 100%;
}
</style>
