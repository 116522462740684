<template>
  <div class="">
    <div class="resume m-3">
      <h4>Resumo</h4>
    </div>
    <div
      class="col-md-12 p-0 d-flex align-items-center justify-content-between"
    >
      <div class="col-8 p-0">
        <containerPedidos
          v-for="(item, index) in itemRequest.servicesOrder"
          :key="index"
          :item="item"
        />
      </div>
      <div class="col-4 d-flex align-items-center justify-content-center">
        <!-- component valor total -->
        <valueAll :price="itemRequest.total" />
      </div>
    </div>
    <hr />
    <div>
      <!-- component criar usuario -->
      <createUser :inputCpf.sync="cpf" :inputName.sync="name" @users="requestUser" :updateUserData.sync="updateUserData" @updateValueReset="updateUser" :dataUpdate='dataUpdate'/>
    </div>
    <hr v-if="itemRequest.total > 0" />
    <div>
      <!-- component Pagamento -->
      <CashierValues :data.sync="itemRequest" v-if="itemRequest.total > 0" />
    </div>
    <userCliente  :title="title" :user="userRequest" @updateUser="updateUser" />
  </div>
</template>

<script>
import containerPedidos from "./containerPedidos";
import valueAll from "./ValueAll";
import createUser from "./createUser";
import CashierValues from "./CashierValues";
import userCliente from '@/components/cliente/userCliente.vue'

export default {
  props: {
    itemRequest: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      cpf: "",
      name: "",
      userRequest: {},
      title: '',
      idCreateUser: '',
      updateUserData: false,
      dataUpdate: {},
      fields: false
    };
  },
  components: {
    containerPedidos,
    valueAll,
    createUser,
    CashierValues,
    // registrationClient: () =>
    //   import("@/components/cliente/modal_cadastroCliente.vue"),
    // editClient: () => import("@/components/cliente/modal_editCliente.vue"),
    userCliente
  },
  methods: {
    requestUser(item) {
      this.userRequest = item;
      this.cp = this.userRequest.id == "" ? "registrationClient" : "editClient";
      const { id } = item
      if (id) {
        this.title = 'Detalhes do Cliente'
        return
      }
      this.title = 'Cadastrar Cliente'
    },
    updateUser(value, data) {
      if(value) {
        this.updateUserData = false
        return
      }
      this.dataUpdate = data
      this.updateUserData = true
    },
    
  }
};
</script>

<style scoped>
.resume h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

hr {
  height: 2px;
  border-radius: 5px;
  background: #3f88c1;
}
</style>
