<template>
  <div>
    <div>
      <div
        class="d-flex align-items-center justify-content-between containerDiv"
        :style="{ visibility: !checkBox ? 'visible' : 'hidden' }"
      >
        <label v-for="item in dataField" :key="item.id" class="containerCheck">
          {{ item.label }}
          <input
            type="radio"
            :checked="item.checked"
            name="radio"
            class="input"
            @click="checkeds(item)"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div
        class="d-flex align-items-center"
        :style="{ visibility: !checkBox ? 'visible' : 'hidden' }"
      >
        <ValidationProvider
          v-if="changeInput"
          v-slot="{ classes, validate, valid }"
          rules="required|min:11"
          name="CPF"
          tag="div"
          :bails="false"
        >
          <the-mask
            type="text"
            class="inputValidate input_default form-control"
            mask="###.###.###-##"
            @blur.native="validate($event)"
            @input="lengthValues"
            v-model="cpf"
            placeholder="000.000.000-00"
            :class="classes"
            :masked="false"
          />
        </ValidationProvider>
        <ValidationProvider
          v-else
          rules="required"
          name="Name"
          tag="div"
          :bails="false"
        >
          <input
            type="text"
            class="inputValidate input_default form-control"
            v-model="nameUser"
            placeholder="Nome do Cliente"
          />
        </ValidationProvider>

        <half-circle-spinner
          :animation-duration="1000"
          :size="30"
          color="#fff"
          class="ml-2"
          v-if="load"
        />
        <div v-if="validateBtn">
          <button class="btnDefault ml-3" @click="openModal" v-if="create">
            <img src="@/assets/image/pedidos/add.png" alt="add" />
            Cadastrar
          </button>
          <button class="btnDefault ml-3" @click="openModal" v-else>
            Ver Cliente
          </button>
        </div>
      </div>
      <div class="d-flex align-items-center containerCheck" v-if="!getpedidos.clientRequired">
        <input type="checkbox" id="cpf" class="checks" v-model="checkBox" />
        <label for="cpf" class="ml-3 labelCheck">
          Cliente <strong>NÃO</strong> sabe o CPF
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters } from 'vuex'
import HTTP from "@/api_system";
export default {
  components: {
    TheMask,
    HalfCircleSpinner
  },
  props: {
    inputCpf: {
      type: String,
      default: ""
    },
    inputName: {
      type: String,
      default: ""
    },
    updateUserData: {
      type: Boolean,
      default: false
    },
    dataUpdate: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      cpf: "",
      nameUser: "",
      isButton: false,
      changeInput: true,
      checkBox: false,
      load: false,
      create: true,
      validateBtn: false,
      searchTimeOut: null,
      itemCreate: {
        id: "",
        name: "",
        cellPhone: "",
        birthDate: "",
        email: "",
        voterRegistration: "",
        cnpj: "",
        city: "",
        stateCity: "",
        genre: "3"
      },
      dataField: [
        {
          id: 1,
          data: "cpf",
          label: "CPF do Cliente",
          checked: true
        },
        {
          id: 2,
          data: "name",
          label: "Nome do Cliente",
          checked: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['getpedidos'])
  },
  mounted() {
    if(this.getpedidos.clientRequired) {
      this.checkBox = false
      return
    }
    this.checkBox = this.$store.getters.cpfUserUndefined;
  },
  methods: {
    openModal() {
      $("#modal_client").modal("show");
      this.$store.commit('setSaveUser', true)
    },
    checkeds({ data }) {
      if (data === "name") {
        this.changeInput = false;
        this.cpf = "";
        this.dataField[0].checked = false
        this.dataField[1].checked = true
        return;
      }

      this.dataField[0].checked = true
      this.dataField[1].checked = false
      this.nameUser = "";
      this.isButton = false;
      this.changeInput = true;
    },
    sendRequestUser() {
      this.checkUser(this.cpf, true);
    },
    checkUser(params, isDocument) {
      this.validateBtn = false;
      this.load = true;
      HTTP.get(
        `services/app/Client/GetByCPF?param=${params}&isDocument=${isDocument}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(({ data }) => {
          // console.log(data.result.id)
          this.nameUser != data.result.name ? this.nameUser = data.result.name : this.nameUser
          this.$store.commit("userCliente", data.result.id);
          this.$emit("users", {...data.result, birthDate: data.result.birthDate.substring(0, 10)});
          this.validateBtn = true;
          this.create = false;
          this.load = false;
        })
        .catch(err => {
          if(this.nameUser) {
            this.$emit("users", { ...this.itemCreate, cpf: this.cpf, name: this.nameUser });
          } else {
            this.$emit("users", { ...this.itemCreate, cpf: this.cpf });
          }
          this.$store.commit("userCliente", null);
          this.toast(
            "Ops! Cliente não foi encontrado ou não esta cadastrado",
            this.$toast.error
          );
          this.load = false;
          this.create = true;
          params.length > 0 ? this.validateBtn = true : this.validateBtn = false

        });
    },
    lengthValues() {
      this.$emit("update:inputCpf", this.cpf);

      if (this.cpf.length === 11) {
        this.isButton = true;
        return;
      }

      this.isButton = false;

      return;
    }
  },
  watch: {
    inputCpf(newValue, oldValue) {
      this.cpf = newValue;
      if (newValue.length == 11) {
        this.checkUser(this.cpf, true);
      }
      this.validateBtn = false;
    },
    checkBox(newValue) {
      this.$store.commit("cpfUndefined", newValue);

      this.dataField.forEach(el => {

        if(el.checked) {
          this.checkeds(el)
        }

      });

      if (this.checkBox) {
        this.nameUser = "";
        this.cpf = "";
        this.validateBtn = false;
        this.isButton = false;
        this.changeInput = true;
        this.data = "cpf";
      }
    },
    inputName: function(newValue) {
      console.log(newValue)
    },
    nameUser: function() {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(() => {
        this.checkUser(this.nameUser, false);
      }, 1000);
    },
    updateUserData: function(value) {
      if(value) {
        setTimeout(() => {
          this.checkUser(this.dataUpdate.cpf, true)
        }, 1000)
      }
      this.$emit('updateValueReset', true, {})
    }
  }
};
</script>

<style scoped>
.containerDiv {
  width: 28%;
  margin: 25px 0;
}
.containerCheck {
  display: block;
  position: relative;
  padding: 0 0 0 21px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerCheck .input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
}

.containerCheck:hover .input ~ .checkmark {
  background-color: #fff;
}

.containerCheck .input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheck .input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f38235;
}

label {
  margin: 0;
}

.inputValidate {
  width: 204px;
  height: 40px;
  border: 1px solid #206aad;
  border-radius: 4px !important;
  text-align: initial;
  padding: 0 0 0 10px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
}

.btnDefault {
  background: #f38235;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  width: 162px;
  padding: 0 20px;
  border: none;
  transition: all 0.2s linear;
  outline: none;
}
.btnDefault:hover {
  background-color: #e0a638;
}
.checks {
  height: 20px;
  width: 20px;
}
.containerCheck .labelCheck {
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
}
.containerCheck .labelCheck strong {
  font-weight: 700;
}
</style>
