<template>
    <div @closeModal="closeModal" class="row align-items-center" id="row">
        <div class="col-4" id="servico">
          <p class="servico_descricao mb-0">
            Abertura de conta Santander
          </p>
        </div>
        <div class="col-4" id="andamento">
          <p class="servico_descricao mb-0">
            Em andamento
          </p>
        </div>
        <div @click="rotaModal('/pedidos')" class="arrow col-4" id="arrow">
            <p class="servico_descricao mb-0" id="arrowFrase">Ver pedido</p><i class="fas fa-arrow-right"></i>
        </div>
      </div>
</template>

<script>
export default {
  methods:{
    rotaModal(rota){
      if (this.$route.path !== this.$router.push(rota)) {
			this.$router.push(rota);
			this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  }

}
</script>

<style scoped>
#servico{
    display: flex!important;
    justify-content: left!important;
}
#andamento{
    display: flex!important;
    justify-content: right!important;
}
#row {
  height: 25px!important;
  border-radius: 3px;
  background-color: #388ddc;
  color: #FFF;
  border: none;
  margin-bottom: 5px;
}
.servico_descricao {
  color: #FFF;
  font-weight: 400;
  font-size: 14px;
}
#progresso{
  color:#FFF!important;
}

.labelContainer{
  width: 100%;
}
#dois{
  padding-left: 40%;
}
#tres{
  float: right;
}
#arrow{
  display: flex!important;
  justify-content: right;
  float: right!important;
}

.row {
  height: 45px!important;
  border-radius: 10px;
  background: #FFF;
  border: 1px #84b9e1 solid;
  margin-bottom: 5px;
}
.col-4{
  display: flex;
  justify-content: center;
}
.p{
  color: #FFF;
  font-weight: 400;
  font-size: 10px;
}

#eyeContainer{
  padding-left: 5px;
}
#arrowFrase{
    padding-right: 5px ;
}
</style>
